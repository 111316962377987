import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const items = [
  { key: 'over-ons',
    label: 'Over ons' },
  { key: 'visie',
    label: 'Visie' },
  { key: 'info',
    label: 'Info' },
  { key: 'tarieven',
    label: 'Tarieven' },
  { key: 'openingstijden',
    label: 'Openingstijden' },
  { key: 'activiteiten-en-aanbod',
    label: 'Activiteiten & aanbod' },
  { key: 'contact',
    label: 'Contact' }
]

const Nav = props => (
  <nav id='nav' className={props.sticky ? 'alt' : ''}>
    <Scrollspy
      items={items.map(item => item.label)}
      currentClassName='is-active'
      offset={-300}>
      {items.map(item => {
        return <li key={item.key}>
          <Scroll type='id' element={item.key}>
            <a href={'#' + item.key}>{item.label}</a>
          </Scroll>
        </li>
      }
      )}
    </Scrollspy>
  </nav>
)

export default Nav
