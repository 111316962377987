import React from 'react'

import logo from '../assets/images/logo.svg'
import styled from 'styled-components'

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
`

const MarketingLine = styled.div`
margin-right: auto;

`

const Header = props => (
  <header id='header' className='alt'>
    {/* Logo naar rechts */}
    <LogoWrapper>
      <div className='logo'>
        <img src={logo} width={200} alt='' />
      </div>
      <MarketingLine>
        <p>Vind je eigen wereld en de wereld vindt jou</p>
      </MarketingLine>
    </LogoWrapper>
  </header>
)

export default Header
