import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import Waypoint from 'react-waypoint'

import Layout from '../components/layout'
import Header from '../components/Header'
import ContactForm from '../components/ContactForm'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    let props = this.props;
    return (
      <Layout>
        <Img
          sizes={props.data.bg.childImageSharp.fluid}
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: -1
          }}
        />
        <Helmet title="Kinderopvang de speurtocht - Kodespeurtocht" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        />
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="over-ons" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Over ons</h2>
                </header>
                <p>
                  Kinderopvang De Speurtocht is een VOF van Sarah Arts en Hilde Boerboom.
                  Wij zijn 2 dames die onze passie gevonden hebben in de kinderopvang en deze willen doen
                  uitstralen. Door jaren van ervaring op te doen in het werkveld willen we dit tot uiting brengen
                  in ons bedrijf de speurtocht.
                </p>
                <p>
                  De Speurtocht streeft er naar om een flexibele opvang te bieden, waarin we ouders
                  begeleiden en tegemoetkomen op verschillende gebieden. En waarin we kinderen activeren
                  en uitdagen met een zintuig prikkelende, stimulerende en warme omgeving.
                </p>
                <p>
                  Wij streven er naar kinderen hun eigenheid te laten ontwikkelen door middel van de
                  juiste uitdaging en begeleiding. Veel buiten spelen, creatief zijn en koken. Maar
                  natuurlijk leven we in een tijd waarin het beeldscherm een grote rol speelt dit beperken we
                  tot een minimum. Kinderen bekend laten worden met elkaars culturen en respect voor
                  elkaars eigenheid is een van de kern punten.
                </p>
                <p>
                  Het betrekken van ouders is daarom in onze ogen ook van groot belang opvoeden doen we
                  samen en ouders zijn de hoofdfactoren hier natuurlijk in!
                </p>
                {/*<ul className="actions">*/}
                {/*<li>*/}
                {/*<Link to="/generic" className="button">*/}
                {/*Learn More*/}
                {/*</Link>*/}
                {/*</li>*/}
                {/*</ul>*/}
              </div>
              <span className="image">
                <Img fluid={props.data.samen.childImageSharp.fluid} />
              </span>
            </div>
          </section>

          <section id="visie" className="main">
            <header className="major">
              <h2>Visie</h2>
            </header>
            <p>
              De visie van de Speurtocht is gebaseerd op basis van wederzijds respect. Hierbij
              streven wij ernaar om elk kind zo individueel mogelijk te benaderen en in zijn eigen
              waarde te laten. Kinderen leren het meest van elkaar; volwassenen kunnen dit
              bevorderen door het goede voorbeeld te geven en kinderen te begeleiden in een
              juiste vorm van omgang en contact. We willen graag een plaats zijn waar het kind zich veilig, thuis en geliefd voelt en
              waar alle aandacht aan hem/haar wordt besteed. Een warme belangstelling voor het
              kind, contact met de natuur en vaste rituelen zijn hiervoor belangrijke hulpmiddelen.
              Vanuit onze visie heeft elk kind speciale talenten en motieven die hem of haar
              vormen tot wie het kind wil zijn.
            </p>
            <p>Kernpunten uit onze visie</p>
            <ul>
              <li>Wij bieden een warme, veilige, uitdagende en rustige omgeving.</li>
              <li>Natuur en cultuur vinden wij van onschatbare waarden in de opvoeding.</li>
              <li>Onze benadering gaat uit van respect voor de eigenheid van het kind.</li>
              <li>Wij leren het kind respect te hebben, zowel voor zichzelf als voor anderen.</li>
              <li>We benadrukken een gelijkwaardige relatie tussen kind en volwassene.</li>
              <li>Wij praten mét kinderen, niet tegen of over kinderen.</li>
              <li>Wij laten het initiatief vanuit het kind komen.</li>
              <li>Wij hechten veel waarde aan kinder- en ouderparticipatie.</li>
              <li>Wij bevorderen de zintuiglijke ontwikkeling en het uiten van gevoelens via creatieve vormen.</li>
              <li>Wij stimuleren zelfredzaamheid.</li>
            </ul>
            {/*<ul className="features">*/}
            {/*<li>*/}
            {/*<span className="icon major style1 fa-code" />*/}
            {/*<h3>Ipsum consequat</h3>*/}
            {/*<p>*/}
            {/*Sed lorem amet ipsum dolor et amet nullam consequat a feugiat*/}
            {/*consequat tempus veroeros sed consequat.*/}
            {/*</p>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*<span className="icon major style3 fa-copy" />*/}
            {/*<h3>Amed sed feugiat</h3>*/}
            {/*<p>*/}
            {/*Sed lorem amet ipsum dolor et amet nullam consequat a feugiat*/}
            {/*consequat tempus veroeros sed consequat.*/}
            {/*</p>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*<span className="icon major style5 fa-diamond" />*/}
            {/*<h3>Dolor nullam</h3>*/}
            {/*<p>*/}
            {/*Sed lorem amet ipsum dolor et amet nullam consequat a feugiat*/}
            {/*consequat tempus veroeros sed consequat.*/}
            {/*</p>*/}
            {/*</li>*/}
            {/*</ul>*/}
            {/*<footer className="major">*/}
            {/*<ul className="actions">*/}
            {/*<li>*/}
            {/*<Link to="/generic" className="button">*/}
            {/*Learn More*/}
            {/*</Link>*/}
            {/*</li>*/}
            {/*</ul>*/}
            {/*</footer>*/}
          </section>


          <section id="info" className="main">
            <header className="major">
              <h2>Info</h2>
            </header>
            <h3>Ons product:</h3>
            <p>Kinderdagverblijf De Speurtocht biedt:</p>
            <ul>
              <li>opvang van maximaal 12 uur per dag aan (van 7:00 uur tot 19:00 uur),</li>
              <li>het eerste uur van de dag kan ingekocht worden met ontbijt erbij en het laatste uur van de dag met een warme maaltijd,</li>
              <li>een veilige plaats en ook een ruimte waar kinderen zich goed kunnen ontwikkelen.</li>
            </ul>
            <p>Uniek</p>
            <ul>
              <li>Zaterdagopvang 10.00 -16.00</li>
              <li>Activiteiten en workshops</li>
              <li>12uur opvang</li>
              <li>Ontbijt en warme maaltijd service</li>
            </ul>
          </section>


          <section id="tarieven" className="main">
            <header className="major">
              <h2>Tarieven</h2>
            </header>
            <ul>
              <li>€8,02 per uur per kind - PSZ en verticaal</li>
              <li>€7,05 per uur per kind - BSO</li>
              <li>Ochtend en avond opvang bedraagt hetzelfde uur tarief als de BSO</li>
              <li>Maaltijden, luiers, pedagogische advies van de medewerkers en uitstapjes zitten bij de prijs inbegrepen</li>
            </ul>
          </section>

          <section id="openingstijden" className="main">
            <header className="major">
              <h2>Openingstijden</h2>
            </header>
            <dl>
              <dt>Kinderopvang verticale groep 0-4 jaar</dt>
              <dd>Maandag t/m vrijdag van 7:00 – 19:00</dd>
              <dt>Peuterspeelzaal 2-4 jaar</dt>
              <dd>Maandag t/m vrijdag 8:00-12:00 uitgezonderd schoolvakantie</dd>
              <dt>Buitenschoolse opvang 4-13 jaar</dt>
              <dd>
                Maandag 13.00 – 18:00<br/>
                Dinsdag en donderdag 14.00 – 18:00<br/>
                Woensdag en vrijdag 12.00-18.00<br/>
                Tijdens schoolvakanties 08.00-18.00
              </dd>
              <dt>Voorschoolse opvang zowel voor 0-4 jaar als 4-13 jaar.</dt>
              <dd>Maandag t/ vrijdag 07:00-08.30</dd>
              <dt>Avond opvang</dt>
              <dd>maandag t/m vrijdag 18.00-19.00</dd>
            </dl>
          </section>

          <section id="activiteiten-en-aanbod" className="main">
            <header className="major">
              <h2>Activiteiten & aanbod</h2>
            </header>
            <h3>Aanbod</h3>
            <ul>
              <li>Goede kindvriendelijke en uitdagende opvang</li>
              <li>Zaterdagopvang 10.00-16.00</li>
              <li>Activiteiten en workshops</li>
              <li>Thuis breng service</li>
              <li>12uur opvang</li>
              <li>Ontbijt en warme maaltijd servic</li>
            </ul>
            <h3>Workshops en activiteiten</h3>
            <ul>
              <li>Elke dag knutsel activiteit aangeboden</li>
              <li>Creatieve workshops zoals:
                <ul>
                  <li>pottenbakken</li>
                  <li>kleien</li>
                  <li>schilderen</li>
                  <li>portret tekenen</li>
                  <li>speksteen bewerken</li>
                </ul>
              </li>
              <li>Kook en bak workshops</li>
              <li>Muziek en dans</li>
              <li>Drama expressie en toneel</li>
            </ul>
          </section>

          <section id="contact" className="main">
            <header className="major">
              <h2>Contact</h2>
            </header>
            <ContactForm/>
          </section>

          {/*<section id="second" className="main special">*/}
          {/*<header className="major">*/}
          {/*<h2>Ipsum consequat</h2>*/}
          {/*<p>*/}
          {/*Donec imperdiet consequat consequat. Suspendisse feugiat congue*/}
          {/*<br />*/}
          {/*posuere. Nulla massa urna, fermentum eget quam aliquet.*/}
          {/*</p>*/}
          {/*</header>*/}
          {/*<ul className="statistics">*/}
          {/*<li className="style1">*/}
          {/*<span className="icon fa-code-fork" />*/}
          {/*<strong>5,120</strong> Etiam*/}
          {/*</li>*/}
          {/*<li className="style2">*/}
          {/*<span className="icon fa-folder-open-o" />*/}
          {/*<strong>8,192</strong> Magna*/}
          {/*</li>*/}
          {/*<li className="style3">*/}
          {/*<span className="icon fa-signal" />*/}
          {/*<strong>2,048</strong> Tempus*/}
          {/*</li>*/}
          {/*<li className="style4">*/}
          {/*<span className="icon fa-laptop" />*/}
          {/*<strong>4,096</strong> Aliquam*/}
          {/*</li>*/}
          {/*<li className="style5">*/}
          {/*<span className="icon fa-diamond" />*/}
          {/*<strong>1,024</strong> Nullam*/}
          {/*</li>*/}
          {/*</ul>*/}
          {/*<p className="content">*/}
          {/*Nam elementum nisl et mi a commodo porttitor. Morbi sit amet nisl*/}
          {/*eu arcu faucibus hendrerit vel a risus. Nam a orci mi, elementum*/}
          {/*ac arcu sit amet, fermentum pellentesque et purus. Integer maximus*/}
          {/*varius lorem, sed convallis diam accumsan sed. Etiam porttitor*/}
          {/*placerat sapien, sed eleifend a enim pulvinar faucibus semper quis*/}
          {/*ut arcu. Ut non nisl a mollis est efficitur vestibulum. Integer*/}
          {/*eget purus nec nulla mattis et accumsan ut magna libero. Morbi*/}
          {/*auctor iaculis porttitor. Sed ut magna ac risus et hendrerit*/}
          {/*scelerisque. Praesent eleifend lacus in lectus aliquam porta. Cras*/}
          {/*eu ornare dui curabitur lacinia.*/}
          {/*</p>*/}
          {/*<footer className="major">*/}
          {/*<ul className="actions">*/}
          {/*<li>*/}
          {/*<Link to="/generic" className="button">*/}
          {/*Learn More*/}
          {/*</Link>*/}
          {/*</li>*/}
          {/*</ul>*/}
          {/*</footer>*/}
          {/*</section>*/}

          {/*<section id="cta" className="main special">*/}
          {/*<header className="major">*/}
          {/*<h2>Congue imperdiet</h2>*/}
          {/*<p>*/}
          {/*Donec imperdiet consequat consequat. Suspendisse feugiat congue*/}
          {/*<br />*/}
          {/*posuere. Nulla massa urna, fermentum eget quam aliquet.*/}
          {/*</p>*/}
          {/*</header>*/}
          {/*<footer className="major">*/}
          {/*<ul className="actions">*/}
          {/*<li>*/}
          {/*<Link to="/generic" className="button special">*/}
          {/*Get Started*/}
          {/*</Link>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*<Link to="/generic" className="button">*/}
          {/*Learn More*/}
          {/*</Link>*/}
          {/*</li>*/}
          {/*</ul>*/}
          {/*</footer>*/}
          {/*</section>*/}
        </div>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
{
  bg: file(relativePath: {eq: "website-bg.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2560, quality: 75) {
          ...GatsbyImageSharpFluid
      }
    }
  }
  samen: file(relativePath: {eq: "samen.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 300, quality: 75) {
          ...GatsbyImageSharpFluid
      }
    }
  }
}
`
