import React from 'react'

import firebase from '@firebase/app'
import '@firebase/functions'

import styled from 'styled-components'

const firebaseConfig = {
  apiKey: 'AIzaSyCFO6f79GTtqxyKjCcySBBSe7d9QFZGfZ4',
  authDomain: 'kodespeurtocht-eu.firebaseapp.com',
  databaseURL: 'https://kodespeurtocht-eu.firebaseio.com',
  projectId: 'kodespeurtocht-eu',
  storageBucket: 'kodespeurtocht-eu.appspot.com',
  messagingSenderId: '675566471743',
  appId: '1:675566471743:web:66547dc26a89ce18'
}

let isInitialized = false

let addMessage = null

const sendEmail = (e) => {
  if (typeof window !== 'undefined') {
    e.preventDefault()

    if (!isInitialized) {
      firebase.initializeApp(firebaseConfig)
      isInitialized = true

      addMessage = firebase
        .app()
        .functions('europe-west1')
        .httpsCallable('sendContactForm')
    }

    let formData = new FormData(e.target)
    let data = {}
    for (let pair of formData.entries()) {
      data[pair[0]] = pair[1]
    }

    console.log(data)

    addMessage(data).then(function (result) {
      console.log(result)
    })
  }
}

const FieldWrapper = styled.div`
   margin-bottom: 10px;
`

class ContactForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      disabled: true
    }
  }

  componentDidMount () {
    this.setState({ email: 'mitchelkuijpers@gmail.com', disabled: false })
  }

  render () {
    return (
      <form onSubmit={sendEmail}>
        <FieldWrapper>
          <input type='text' id='name' name='naam' placeholder='Naam' required />
        </FieldWrapper>

        <FieldWrapper>
          <input type='text' id='subject' name='onderwerp' placeholder='Onderwerp' required />
        </FieldWrapper>

        <FieldWrapper>
          <input type='tel' name='telefoonnummer' placeholder='Telefoonnummer' />
        </FieldWrapper>

        <FieldWrapper>
          <input type='email' name='email' placeholder='Email' required />
        </FieldWrapper>

        <FieldWrapper>
          <textarea name='opmerking' placeholder='Vragen en of opmerkingen' required />
        </FieldWrapper>
        <button disabled={this.state.disabled} type='submit'>Verstuur</button>
      </form>)
  }
}

export default ContactForm
